import React, { useEffect } from 'react';
import { Link } from 'react-router-dom'
import "swiper/css/autoplay";
// import Swiper core and required modules
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, {
  Autoplay,
  EffectFade,
  Navigation,
  Pagination,
} from "swiper";
SwiperCore.use([Navigation, Pagination, EffectFade, Autoplay]);

function ProjectDetailsContent() {
  useEffect(() => {
    window.scrollTo(0, 0); // Sets the scroll position to the top without any animation
  }, []);

  const projectSlider = {
    loop: true,
    slidesPerView: 1,
    navigation: {
      nextEl: ".swiper-button-next-c",
      prevEl: ".swiper-button-prev-c",
    },
  };
  return (
    <>
     <div className="project-details-content">
        <div className="project-thumb">
          <img src={process.env.PUBLIC_URL + '/img/project/thumbnail-project.jpg'} alt="mages" />
          {/* <div className="tag">
            <Link to={`${process.env.PUBLIC_URL}/project-details`} onClick={window.scrollTo({top:0,behavior:"smooth"})}>Software</Link>
          </div> */}
        </div>
        <h3>E-Commerce Platform Development: Building a Robust and Scalable Solution
        </h3>
        <p>Our e-commerce platform development service offers a robust and scalable solution designed to empower businesses to efficiently manage online transactions, product catalogs, customer service, and order fulfillment. Whether you're a growing retailer or an established brand, this platform provides all the tools you need to enhance your online presence and streamline your operations.
</p>
        <div className="clinet-need">
          <h4>Key Features:</h4>
          <h5>1.Scalability for Growth</h5>
          <p> Built to handle high volumes of traffic and transactions, our platform grows with your business, ensuring seamless performance even during peak times.
          </p>
          <h5>2.User-Friendly Interface
          </h5>
          <p>The intuitive design makes it easy for both your team and customers to navigate the platform, browse products, and complete purchases without any friction.
          </p>
          <h5>3.Secure Payment Processing</h5>
          <p> Integrated with the latest security protocols, our platform ensures that all customer transactions are processed safely, protecting both your business and your customers from fraud.</p>
          <h5>4.Comprehensive Product Catalog Management</h5>
          <p> Easily manage your product listings, update inventory, and categorize items to ensure a smooth shopping experience.</p>
          <h5>5.Order Fulfillment Automation</h5>
          <p>From order tracking to delivery, our platform streamlines the entire fulfillment process, reducing manual efforts and speeding up delivery times.</p>
          <h5>6.Customer Service Tools</h5>
          <p>Engage with your customers through built-in communication channels, live chat, and support ticket systems to resolve queries quickly and enhance customer satisfaction.</p>
          <h5>7.Third-Party Integrations</h5>
          <p>Seamlessly connect with popular tools like Shopify, WooCommerce, and payment gateways, as well as marketing platforms like Google Analytics, to enhance your e-commerce capabilities.
          </p>
          <br/>
          <br/>
          <p>This platform is the ideal solution for businesses seeking to improve efficiency, boost sales, and provide an exceptional customer experience. Whether you're looking to expand your digital storefront or optimize your current operations, our e-commerce platform development service has you covered.</p>
          <p>Elevate your online business with a solution designed to adapt, scale, and perform at every stage of your growth.</p>
        </div>
        {/* <div className="working-process">
          <h4>Working Process</h4>
          <Swiper {...projectSlider} className="swiper work-process-slide">
            <div className="swiper-wrapper">
              <SwiperSlide className="swiper-slide">
                <div className="work-thumb">
                  <img src={process.env.PUBLIC_URL + "/img/project/work-thumb-slide.jpg"} alt="mages" />
                  <div className="label">
                    <span>01. Brainstorming</span>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide className="swiper-slide">
                <div className="work-thumb">
                  <img src={process.env.PUBLIC_URL + '/img/project/work-thumb-slide-1.jpg'} alt="mages" />
                  <div className="label">
                    <span>02. Drawing</span>
                  </div>
                </div>
              </SwiperSlide>
            </div>
            <div className="arrow">
              <div className="swiper-button-next-c"><i className="bi bi-arrow-right" /></div>
              <div className="swiper-button-prev-c"><i className="bi bi-arrow-left" /></div>
            </div>
          </Swiper>
          <p>Interdum et malesuada fames ac ante ipsum primis in faucibus. Etiam eu nibh elementum, accumsan ona neque ac, aliquet nunc. In eu ipsum fringilla, accumsan purus vel, pellentesque risus. Vivamus vehicula nl purus at eros interdum, in dignissim nulla vestibulum. Nunc sit amet finibus felis, ut egestas lacus. Sedan pellentesque quis magna eu vestibulum. Ut sed commodo neque. Morbi erat nis</p>
        </div> */}
        {/* <div className="check-lunch">
          <h4>Check &amp; Launch</h4>
          <div className="row">
            <div className="col-lg-6">
              <ul className="check">
                <li><i><img src={process.env.PUBLIC_URL + '/img/icons/check.svg'} alt="mages" /></i>Aenean vitae pharetra leo. Aliquam pri.</li>
                <li><i><img src={process.env.PUBLIC_URL + '/img/icons/check.svg'} alt="mages" /></i>In aliquet, quam vitae blandit temporg.</li>
                <li><i><img src={process.env.PUBLIC_URL + '/img/icons/check.svg'} alt="mages" /></i>Aenean volutpat enim vitae tincidunta.</li>
                <li><i><img src={process.env.PUBLIC_URL + '/img/icons/check.svg'} alt="mages" /></i>Felis at venenatis porttitor, nunc arcua.</li>
                <li><i><img src={process.env.PUBLIC_URL + '/img/icons/check.svg'} alt="mages" /></i>Maximus felis quam vitae tortor phase.</li>
                <li><i><img src={process.env.PUBLIC_URL + '/img/icons/check.svg'} alt="mages" /></i>Nulla vulputate urna quis ex pellentes.</li>
              </ul>
            </div>
            <div className="col-lg-6">
              <p>Interdum et malesuada fames ac ante ipsum tobal primis in faucibus. Etiam eu nibh elementum, tolda accumsan ona In eu ipsum fringilla, accumsan pur neque ac, aliquet nunc. In eu ipsum fringilla, wolan accumsan purus vel, pellentesque risus. Vivamusa
                purus at eros interdum, in dignissim nulla Ut sedal vestibulum. Nunc sit amet finibus felis, ut egestas lacus. Sedan commodo neque. Morbi erat niswole pellentesque quis magna eu vestibulum. </p>
            </div>
          </div>
        </div> */}
      </div>   
    </>
  )
}

export default ProjectDetailsContent